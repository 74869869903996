//
// Buttons, Lists, and other various ui elements
//

.content {
  padding-bottom: 6em;
}

.banner-parent {
  display: flex;
  height: 100vh;
  justify-content: space-evenly;
  flex-direction: column;
  flex-grow: 1;
  z-index: 101;
}

// LISTS
.btn {
  font-family: $secondary-font-family;
  background-color: darken($color-tertiary, 4%);
  color: #fff !important;
  transition: all 0.3s ease;
  letter-spacing: 0.025em;
  border: 0;
  border-radius: 0.25em;

  &:hover {
    background-color: darken($color-secondary, 25%);
  }
}

.btn-primary {
  font-weight: 700;
  background-image: none;
}

.btn-xl {
  margin-top: 0.25em;
}


// LISTS
ul.post-list {
  margin-left: 0;
  margin-bottom: 10px;

  li {
    margin-bottom: 40px;
  }

  // Make an exception and remove left margin
  .post {
    padding-top: 20px;
  }
}

#services {
  .service-cards {
    padding-bottom: $spacing-unit*4;

    i.fa {
      color: $color-tertiary !important;
    }
  }
}

.muted-icon {
  opacity: 0.65;
}

textarea.form-control {
  padding: 6px 6px;
}

.icon-bar+.icon-bar {
  margin-top: 5px;
  // background: $color-primary;
}

.icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background: #fff;
}

textarea {
  width: 90%;
}

input.form-control,
textarea.form-control {
  font-size: $font-size-base;
  transition: all 0.4s ease;
  background-color: $color-gray-lightest;
  color: $color-gray-darkest;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid $color-gray-dark;
  padding: 10px 6px;
  box-shadow: 0;
  outline: 0;

  &:active,
  &:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid $color-secondary;
    box-shadow: none !important;
    background-color: lighten($color-secondary, 30%);
  }
}

// Soundcloud and Video embeds
.soundcloud {
  // padding: 1em 2em;
  margin: 1em 0 .7em 0;

  iframe {
    border: 1px solid #ccc;
    border-radius: 3px;
  }
}

// Can also specify video::
*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

// Round images (About team members, Timeline photos)
.team-member {
  margin-bottom: 3.5em;
  text-align: center;

  h4 {
    margin-top: 15px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0.15em;
  }

  img {
    margin: 0 auto;
    box-shadow: 0.15rem .15rem 0.5rem rgba(80, 80, 80, 0.3);
  }
}

#blog img {
  padding: 4em 1em;
}

img.logo-thumb {
  padding: 0 40%;
  opacity: 0.8;
}

// Landing page text
header {
  z-index: 10;
  min-height: 98vh;
  height: auto;
  width: auto;
  text-align: center;
  color: #fff;

  &.small-header {
    min-height: 20vh;
  }

  .btn {
    background: none;
    border: 1px solid #fff;
    cursor: pointer;
  }

  .banner-text,
  .banner-cta {
    text-align: left;
    z-index: 101;
  }
}

.cta {
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 4em;
}

//
.intro-text {
  z-index: 10 !important;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.video-clip-container {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap:   wrap;

  .video-clip {
    padding: 0;
    z-index: 200;
    position: relative;
    height: auto;
    vertical-align: middle;
    padding-bottom: 56.25%;
    transition: opacity 0.4s ease-out;
    overflow: hidden;
    border: 8px solid transparent;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 2rem rgba(20, 20, 0, 0.76);
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 64%;
      // border: 2px solid green;
    }
    @media screen and (min-width: 768px) and (max-width: 950px) {
      padding-bottom: 56.25%;
      // border: 2px solid yellow;
    }
    @media screen and (min-width: 951px) and (max-width: 1600px) {
      padding-bottom: 30%;
      // border: 2px solid blue;
    }
    @media screen and (min-width: 1601px) {
      padding-bottom: 26%;
    }

    iframe {
      z-index: 2;
      position: absolute;
      aspect-ratio: (16/9);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .video-clip__title {
      z-index: 20;
      width: 100%;
      background-color: rgba(0,0,0,1);
      padding: 1.5rem 2.5rem;
      margin: 0;
      color: white;
      position: relative;

      @media screen and (max-width: 767px) {
        font-size: 1rem;
        padding: .5rem .75rem;
      }
    }
    .video-clip__artist {
      font-weight: 600;
      font-size: 2.5rem;
    }
    .video-clip__performance {
      font-weight: 300;
      font-size: 2rem;
      text-transform: uppercase;

    }

  }
}

.video-overlay,
.intro-text,
.video-bg {
  position: absolute !important;
  top: 0;
  left: 0;
}

.video-overlay {
  padding-bottom: 0px !important;
  background: -webkit-gradient(linear, left top, left bottom,
    from(rgba(100, 100, 100, 0)),
    to(rgba(0, 0, 0, 0.4)), color-stop(0, rgba(20, 20, 20, 0.45)));

  // background-color: darken($color-gray-darkest, 10);
  opacity: 1;
  min-width: 100%;
  min-height: 100vh;
  width: auto;
  height: auto;
  z-index: 5;
}

// Background Video
.video-wrapper {
  position: absolute;
  margin: 0px;
  padding: 0px;
  top: 0;
  left: 0;
  background-image: url('../img/header-hutchinson.jpg');
  background-position: center center;
  background-repeat: none;
  background-size: cover;
  min-width: 100%;
  min-height: 100%;


  video {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

    min-width: 100%;
    min-height: 100%;

    width: auto;
    height: auto;
    z-index: 1;

    background: rgba(0, 0, 0, 0);
    // display: block;
  }
}

section {
  border-top: 1px solid $color-gray-darker;
  z-index: 10;
  position: relative;
  z-index: 10;
  margin: 0;
  padding: 2.5rem 0;
  background-color: lighten($color-gray-lightest, 10);
}

.social-icon-bar {
  box-shadow: 0.15rem .15rem 0.5rem rgba(80, 80, 80, 0.3);
  width: auto;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.25rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 20;
  position: fixed;
  top: 160px;
  left: 0;
  padding-left: 1rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .25rem 0;
    li {
      font-size: 16px !important;
      margin: .1rem auto;
      display: flex;
      justify-content: center;
      transition: opacity 0.3s ease-out;
      &:hover {
        opacity: 0.6;
      }
    }
  }

}

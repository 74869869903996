//
// Footer
//

footer {
  position: relative;
  background-color: #fff;
  padding: 40px 0;
  text-align: center;

  .site-logo-footer {
    height: 40px;
    width: 186px;
    margin: -12px 0 -7px -3px;
  }

  .copyright {
    color: $color-primary;
    text-transform: uppercase;
    font-weight: 700;
    text-transform: none;
    line-height: 1em;
    margin-bottom: 2em;
  }
  .quicklinks {
    margin-bottom: 0;
    text-transform: uppercase;
    text-transform: none;
    padding: .5em 0;
    font-size: 1em;
    li {
      line-height: 1em;
    }
  }

  ul.social-buttons {
    margin-bottom: 0;

    li a {
      display: block;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      font-size: 25px !important;
      margin: 2px 0 6px 0;
      padding: 10px;
      outline: 0;
      color: #fff;
      background-color: lighten($color-tertiary, 10) !important;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      transition: all .2s;

      // Icon Color
      i {
        color: #fff !important;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: lighten($color-tertiary, 25);
      }
    }
  }
}

// Mixins
%transition-all {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}

@mixin slim-navbar-brand($m) {
s  .navbar-brand {
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: auto;
    min-height: 54px;
    margin: 0.2rem 0;
  }

  img.site-logo {
    display: none;
  }
}

@mixin regular-navbar-brand($m) {
  .navbar-brand {
    background: none;
    width: auto;
    display: flex;
    justify-content: center;
  }

  img.site-logo {
    display: block;
  }
}

@mixin build-responsive-page($m) {
  $top-bottom-padding: .7rem;
  $left-right-padding: 1.25rem;

  section {
    padding: $m*2.5em 0;
  }

  .close-button {
    top: $m * 1.5em;
    right: $m * 2em;
  }

  .client-portfolio {
    .header {
      padding: $m * 2em;
      height: $m * 28em;

      .title {
        position: relative;
        top: $m * 16em;
      }
    }
  }

  .video-clip iframe {
    min-height: $m * 400px;
  }

  // Services page
  .service-caption {
    min-height: $m * 60px;
  }

  .navbar {
    padding-right: 3rem;
    padding-left: 3rem;

    .navbar-brand {
      width: $m * 1rem;

      img.site-logo {
        height: $m * 3rem;
      }
    }

    .navbar-nav {
      margin: $m*.7rem $m*1.25rem;

      >li {
        >.navbar-link {
          font-size: $m * 1em;
          padding: $m*.5em $m*.85em;
          letter-spacing: $m*0.15em;
        }
      }
    }
  }

  // HORIZONTAL RULE
  hr {
    margin: $m * 2rem auto;
  }

  // Specific classes
  .modal-header {
    height: $m * 42%;
  }

  body {
    p {
      font-size: 1em * $m;
      line-height: $base-line-height * $m;

      &.lead {
        font-size: 1.1em * $m;
        line-height: $base-line-height * $m;

      }
    }
  }

  hr.spacer {
    margin: $m*0.25rem auto;
  }

  h1 {
    font-size: 3.5rem * $m;
    margin: $m*0.5rem 0;
  }

  h2 {
    font-size: 2.5rem * $m;
    margin: $m*4rem 0 $m*.5rem 0;
  }

  h3 {
    font-size: 1.6rem * $m;
    margin: $m*1.75rem 0 $m*.5rem 0;
  }

  h4 {
    font-size: 1.3rem * $m;
    margin: $m*1.5rem 0 $m*.5rem 0;
  }

  h5 {
    font-size: 1.2rem * $m;
    margin: $m*1rem 0 $m*.25rem 0;
  }

  h6 {
    font-size: 1rem * $m;
    margin: $m*10px 0;
  }

  // No space above sutitle to keep compact

  // Add space between columns
  *[class^='col-sm'] {
    padding: 0 $m*30px;
  }

  .btn {
    padding: $m*.35em $m*1.25em;
  }

  .btn-md {
    font-size: $m*0.75em;
  }

  .btn-md {
    font-size: $m*1em;
  }

  .btn-lg {
    font-size: $m*1.25em;
  }

  .btn-xl {
    font-size: $m*1.5em;
  }

  .hero {
    font-size: $m * 5rem;
    margin-top: 8vh;
    margin-bottom: 4vh;
    padding: 0 $m*.25em 0 0;
    line-height: $m*1.4em;
  }

  .banner-cta {
    bottom: 1/$m * 10%;
  }
}

@import "variables";

//
// Base elements
//

body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

// Basic styling
body {
  background-color: lighten($color-gray-lightest, 10%);
  webkit-tap-highlight-color: $color-tertiary;
}

// Container Fluid, my way
.borderless {
  padding-left: -15px;
  padding-right: -15px;
}

// Set `margin-bottom` to maintain vertical rhythm
h1, h2, h3, h4, h5, h6,
p, blockquote, pre, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit * 2;
}

// `main` element
main {
  display: block;
}

hr {
  opacity: 0.9;
}

.spacer {
  opacity: 0;
  padding: 1.5rem 0;
}

// Images
img {
  max-width: 100%;
  vertical-align: middle;
}

img.photo {
  padding: 0.75rem;
  margin: 1rem 0 0 0;
  background: #fafafa;
  border: 1px solid darken($color-gray, 10);
}

// Figures
figure > img {
  display: block;
}

.v-center {
  display: flex;
  align-items: center;
}

// Lists
ul, ol {
  margin-bottom: 0;
  margin-left: 1rem;
  li {
    margin-left: 1.5rem;
    line-height: 2.5rem;
  }
}

// Links
a {
  color: darken($color-primary, 10);
  text-decoration: none;

  &:visited {
    color: darken($color-primary, 10);
  }

  &:hover {
    color: $text-color;
    text-decoration: underline;
  }
}

p, h1, h2, h3, h4, h5, h6 {
  a {
    text-decoration: underline;
  }
}

.underline {
  text-decoration: underline;
}

// Blockquotes

blockquote {
  position: relative;
  margin: 2rem 0;
  padding-left: 20px;
  border-width: 0 0 0 8px;
  border-style: solid;
  border-left: 1rem solid $color-secondary;

  @include relative-font-size(1.125);
  letter-spacing: 0.5px;
  font-style: italic;

  // text-transform: uppercase;
  > :last-child {
    margin-bottom: 0;
  }
  &:before {
    position: absolute;
    opacity: 0.9;
    content: "\201C";
    color: lighten($color-primary, 12%);
    left: 4px;
    top: -4px;
    font-family: Georgia, serif;
    font-size: 28px;
  }
}

i.fa {
  width: auto;
  height: auto;
}

label {
  color: darken($color-gray,3%);
}

%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.img-centered {
  margin: 0 auto;
}

.bg-alt {
  background-color: lighten($color-gray, 6);
}

.bg-accent {
  background-color: lighten($color-secondary, 12);
}

//
// Responsive
//
/* --------- 0-320px: iPhone 5 / 6 / 6 Plus ---- */
@media screen and (max-width: 767px) {
 .container { width: 100% }
  @include build-responsive-page(0.9);
  @include slim-navbar-brand(0.9);
  .video-clip { width: 100%; }
}
/* --------- 768px - 950px ---------------------------- */
@media screen and (min-width: 768px) and (max-width: 950px) {
  .container { width: 96% }
  @include build-responsive-page(1.05);
  @include regular-navbar-brand(.7);
  .video-clip { width: 100%; }
}
/* --------- 950px - 1200px ----------------------------- */
@media screen and (min-width: 951px) and (max-width: 1200px) {
  .container { width: 94% }
  @include build-responsive-page(1.1);
  @include regular-navbar-brand(.8);
  .video-clip { width: 50%; }
}
/* --------- 1201px - 1600px ------------------------------ */
@media screen and (min-width: 1201px) {
  .container { width: 90% }
  @include build-responsive-page(1.15);
  @include regular-navbar-brand(.9);
  .video-clip { width: 50%; }
}
